// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
// @import url('https://fonts.googleapis.com/css2?family=Anton&family=Chango&family=Open+Sans+Condensed:wght@300&display=swap');

